import React from 'react';

import { FormControlProps } from '../common/utils/formUtils';
import { Tag } from '../tag';
import { generateHtmlForId } from '../common/utils/utilFunctions';
import { Label } from '../label/Label';
import { SelectItem } from '../dropdowns/selectUtils';

import './tagsList.scss';
import '../common/formControl.scss';

export interface TagsListProps extends FormControlProps {
	/**[required] list of items to select from */
	items: SelectItem[];

	/**[optional] list of items to render as tags */
	selectedItems?: SelectItem[];

	/**[required] the function will be executed when a usr clicks on a Tag item*/
	onItemClick: (item: SelectItem, isActive: boolean) => void;
}

export function TagsList({
	label,
	hiddenLabel,
	labelIcon,
	id,
	inline,
	className = '',
	disabled,
	items,
	onItemClick,
	selectedItems,
	error
}: TagsListProps) {
	const generatedId = id ? id : generateHtmlForId(label);
	const errorMessageId = `error-message-for-${generatedId}`;

	return (
		<div
			className={`tags-list-wrapper common-form-control ${inline ? 'inline-element' : ''} ${
				error ? 'has-error' : ''
			} ${className}`}
			aria-describedby={error && errorMessageId}
		>
			<Label
				text={label}
				htmlFor={generatedId}
				icon={labelIcon}
				inline={inline}
				disabled={disabled}
				hidden={hiddenLabel}
			/>
			{items?.length ? (
				<ul id={generatedId} className='tags-list'>
					{items.map((item, index) => {
						const isItemActive = selectedItems?.find(
							selectedItem => selectedItem.elId === item.elId
						);

						return (
							<li key={`${item.elId}-${index}`}>
								<Tag
									onClick={() => onItemClick(item, !!isItemActive)}
									active={!!isItemActive}
									disabled={disabled}
								>
									{item.value}
								</Tag>
							</li>
						);
					})}
				</ul>
			) : null}
			{error ? (
				<p id={errorMessageId} className={'control-error-message'}>
					{error}
				</p>
			) : null}
		</div>
	);
}
