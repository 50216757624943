import React, { HTMLAttributes, ReactNode } from 'react';

import './tag.scss';

export interface TagProps extends HTMLAttributes<HTMLSpanElement> {
	/**[required] tag content */
	children: ReactNode;

	/**[optional][default=false] is tag active */
	active?: boolean;

	/**[optional][default=false] is tag disabled */
	disabled?: boolean;

	/**[optional][default=true] is tag clickable*/
	clickable?: boolean;

	/**[optional] onClick function */
	onClick?: () => void;

	/**[optional][default=''] add classname */
	className?: string;
}

export function Tag({
	children,
	active = false,
	disabled = false,
	clickable = true,
	onClick,
	className = '',
	...props
}: TagProps) {
	return (
		<span
			onClick={onClick}
			className={`reusable-tag ${active ? 'active-tag' : ''} ${
				disabled ? 'disabled-tag' : ''
			} ${className} ${clickable ? 'clickable-tag' : ''}`}
			{...props}
		>
			{children}
		</span>
	);
}